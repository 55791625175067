var _cate_id = "init_cate_id";
var nav_order = "init_cate_id";

function header() {
  //     var oTop = document.getElementById('header_top');
  var target = 60;
  var userRoleId = $.jStorage.get("localRoleId");

  isLogin();
  showActive();
  getNotify();

  $("#main_focus_img.carousel").carousel({
    interval: 2000
  });
  // 登录时显示下载二维码
  var $handleOpenQrcode = $(".download-qrcode-handle-open");
  var $downLoadQrcode = $("#download-qrcode");
  var $handleCloseQrcode = $(".download-qrcode-handle-close");
  $handleOpenQrcode.on("click", function () {
    $downLoadQrcode.fadeIn();
  });
  $handleCloseQrcode.on("click", function () {
    $downLoadQrcode.fadeOut();
  });

  $("#subscribe").click(function () {
    if ($.jStorage.get("localToken")) {
      ifSubscribe();
    } else {
      showWarning("请您先登录，再进行订阅");
    }
  });

  $('.search-log  .keyword-wra').on('click', function () {
    $('#headerSearchModal').show();
    $('#headerSearchModal input').focus();
  })

  $('#slidenav_search_con').on('click', function () {
    $('#headerSearchModal').show();
    $('#headerSearchModal input').focus();
  })


  $('#closeSearch').on('click', function () {
    $('#headerSearchModal').hide();
  })

  $("#keyword").keyup(function (e) {
    if (e.keyCode == "13") {
      var keyword = $("#keyword").val();
      keyword = keyword.replace(/\%/g, "%25");
      keyword = keyword.replace(/\#/g, "%23");
      keyword = keyword.replace(/\&/g, "%26");
      var url = "/searchresult?keyword=" + keyword;
      window.location.href = url;
    }
  });


  var _objBorderTop = "";
  var _footerOffsetHeight =
    document.getElementById("main-footer").offsetHeight - 10;
  if ($("#hot_articles_container").offset()) {
    _objBorderTop = $("#hot_articles_container").offset().top;
  }

  function sidebarMove() {
    var topPosition = document.body.scrollTop + 30;
    var objBorderTop = document.getElementById("hot_articles_container")
      .offsetTop;
    var objBorderBottom = document.getElementById("hot_articles_container")
      .offsetHeight;
    var moveLine =
      document.getElementById("main-footer").offsetTop -
      document.body.scrollTop +
      50;
    if (topPosition > objBorderTop) {
      $("#hot_articles_container").addClass("sidebar_move_fixed");
      if (moveLine < objBorderBottom) {
        $("#hot_articles_container").css("top", moveLine - objBorderBottom);
      } else {
        $("#hot_articles_container").css("top", 30);
      }
    }

    if (window.screen.availHeight - moveLine > _footerOffsetHeight) {
      $("#exchange_contact_container").addClass("hide");
    } else {
      $("#exchange_contact_container").removeClass("hide");
    }
    if (topPosition < _objBorderTop) {
      $("#hot_articles_container").css("top", "inherit");
      $("#hot_articles_container").removeClass("sidebar_move_fixed");
    }
  }
  if (location.pathname != "/") {
    var beforeScroll =
      document.documentElement.scrollTop || document.body.scrollTop;
    window.onscroll = function () {
      if (_objBorderTop != "") {
        sidebarMove();
      }
      var afterScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      var d = afterScroll - beforeScroll;
      var oWidth = document.body.clientWidth;
      if (oWidth > 768) {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > target) {
          $(".blank").show();
          if (d > 15) {
            $("header").hide();
          }
          if (d < -15) {
            //      $("header").addClass("navbar-fixed-top");
            $("header").show();
          }
          beforeScroll = afterScroll;
        } else {
          //    $("header").removeClass("navbar-fixed-top");
          $("header").show();
          //      $(".blank").hide();
        }
      }
    };
  }

  /* 点击显示搜索框，并使input获得焦点*/

  $("#clicksearch").click(function (e) {
    e.stopPropagation();
    var keyword = $("#keyword")
      .val()
      .trim();
    if (!keyword) {
      $("#keyword").focus();
      return;
    }
    $('#headerSearchModal').hide();
    keyword = keyword.replace(/\%/g, "%25");
    keyword = keyword.replace(/\#/g, "%23");
    keyword = keyword.replace(/\&/g, "%26");
    var url = "/searchresult?keyword=" + keyword;
    window.location.href = url;

  });

  $("#search i,#search").click(function (e) {
    $("#search input").focus();
    e.stopPropagation();
  });

  $("#keyword").click(function (e) {
    e.stopPropagation();
  });

  // 采集切换文章分类（客）
  $("body .header-tab ul").on("click", "li", function (e) {
    // alert($(e.target).attr("data-id"));
    var cateId = $(this)
      .find("a")
      .text();
    sa.track("articleCategory", {
      $lib_detail: "切换文章分类##点击事件##header.js##300",
      category: cateId,
      "PlatformType": "PC"
    });
  });

  //判断是否为手机
  function IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  if (!IsPC()) {
    $("#report_login,#reply_login a, #slidenavbar_login").click(function () {
      var tempUrl = window.location.href;
      $.jStorage.set("localLastUrl", tempUrl);
      var url = "/login_phone";
      location.href = url;
    });
  }

  $("#login_sign_up2").click(function () {
    $("#login_bg").show();
    $("body").addClass("modal-open");
    $("#sign_up_bg")
      .css({
        left: "auto",
        right: "50%"
      })
      .show();
    $(".sign_up_con")
      .show()
      .css({
        opacity: "1"
      });
    $(".sign_in_con").hide();
  });
  //登录
  if (IsPC()) {
    $("#login_sign_in,#reply_login a,#report_login").click(function () {
      showPCRegistForm();
    });
  }

  $("#login_sign_in2").click(function () {
    $("#login_bg").show();
    $("body").addClass("modal-open");
    $("#sign_up_bg")
      .css({
        left: "50%"
      })
      .show();
    $(".sign_in_con")
      .show()
      .css({
        opacity: "1"
      });
    $(".sign_up_con").hide();
  });

  $("#login_bg1").click(function () {
    $("#login_bg").hide();
    $("body").removeClass("modal-open");
    $(".forget_pw").hide();
  });

  /* 点击登录，注册页面右划并隐藏，登录显示 */
  $("#btn_sign_in").click(function () {
    showPCLoginForm();
  });

  /* 点击注册，登录页面左划并隐藏，注册显示 */
  $("#btn_sign_up").click(function () {
    showPCRegistForm();
  });

  //点击背景隐藏登录注册框
  // $("#login_bg, .login_form_close_img").click(function() {
  $(".login_form_close_img").click(function () {
    $("#login_bg").hide();
    $("#download-qrcode").hide();
    $("body").removeClass("modal-open");
    $("#regist_container").hide();
    $("#forget_pwd_container").hide();
    $("#login_container").hide();
    window.history.go(-1);
  });

  document.getElementById("login_on1").onmouseover = function () {
    $("#show_personal").addClass("personal_center_active");
    $("#show_personal .triangle")
      .removeClass("triangle-down")
      .addClass("triangle-up");

    var personal_center = document.getElementById("personal_center_1");
    if (userRoleId) {
      if (userRoleId >= 6) {
        if (!is_master) {
          document.getElementById("to_admin_page1").href =
            "http://devadmin.pencilnews.cn";
        }
        document.getElementById("to_admin_page1").style.display = "block";
        TweenLite.to(personal_center, 0.3, {
          display: "inline-block",
          height: "160px"
        });
      } else {
        TweenLite.to(personal_center, 0.3, {
          display: "inline-block",
          height: "120px"
        });
      }
    } else {
      TweenLite.to(personal_center, 0.3, {
        display: "inline-block",
        height: "120px"
      });
    }
  };
  document.getElementById("login_on1").onmouseout = function () {
    $("#show_personal").removeClass("personal_center_active");
    $("#show_personal .triangle")
      .removeClass("triangle-up")
      .addClass("triangle-down");

    var personal_center = document.getElementById("personal_center_1");
    TweenLite.to(personal_center, 0.3, {
      height: "0",
      display: "none"
    });
  };

  /* 鼠标移入显示个人信息 */
  $("#login_on1").hover(
    function () {
      $("#login_on1>a").css({
        color: "#FFF300",
        background: "#2C2624",
        "box-shadow": "1px 1px 1px #25201E inset, -1px -1px 1px #25201E inset"
      });
    },
    function () {
      $("#login_on1>a").css({
        color: "#D6D6D6",
        background: "#3F3B3A",
        "box-shadow": "none"
      });
    }
  );

  /* 登出 start */
  $("#logout1,#logout2,#slidenav_exit").click(function () {
    $(".js-logout-modal").modal("show");
  });
  $(".js-logout-modal .comfirm-btn .btn-rt").on("click", function () {
    logout();
    //         console.log("logout")
    $(".js-logout-modal").modal("hide");
    $("#slidenav_exit").hide();
    $(".search-log #login_on1").hide();
    //         console.log("logout")

    // $("#login_form").show();
    $(".login_sigin_con").show();
    // $("#login_on2").hide();
    // $("#login_form2").show();
    $(".forget_pw").css({
      display: "none"
    });
  })
  $(".js-logout-modal .comfirm-btn .btn-lf").on("click", function () {
    $(".js-logout-modal").modal("hide");
  })
  /* 登出 end */

  $("#forgetpw").click(function () {
    showPCForgetPwdForm();
  });

  $("#btn_sign_in_2").click(function () {
    showPCLoginForm();
  });

  /* 点击获取验证码按钮，前段验证通过后，发送验证码，倒计时开始 */
  $("#get_cert_btn").click(function () {
    if (validPhone($("#phone_number"))) {
      getCode(function (data) {
        countDown($("#get_cert_btn"), data.time_last);
      });
    }
  });

  /* 重置密码获取验证码按钮 */
  $("#forget_get_cert_btn").click(function () {
    if (validPhone($("#forget_phone_number"))) {
      getForgetCode(function (data) {
        countDown($("#forget_get_cert_btn"), data.time_last);
      });
    }
  });

  /*  当密码框失去焦点时，验证密码，失败则弹窗 */
  $("#pwd").blur(function () {
    validPassword($("#pwd"));
  });

  $("#forget_pwd").blur(function () {
    validPassword($("#forget_pwd"));
  });

  $("#resetpwd").click(function () {
    if (isEnableResetpwd()) {
      showLoadingGif();
      resetPassword();
    }
  });

  /* 验证手机号，验证码，密码不为空，不为空注册按钮可点击 */
  $("#register").click(function () {
    if (isEnableRegister()) {
      showLoadingGif();
      register();
      // $("#register").unbind("click");
    }
  });

  $("#login_button").click(function () {
    if ($("#password").val() <= 0) {
      showWarning("请填写密码！");
    } else if (validUsername()) {
      showLoadingGif();
      login();
    }
  });

  $("#password").keyup(function (e) {
    if (e.keyCode == "13") {
      if (validUsername()) {
        showLoadingGif();
        login();
      }
    }
  });
}

/* 从后台请求认证码 */
function getCode(cb) {
  var phoneNumber = $("#phone_number").val();
  var queryData = {
    sms_type: "reg",
    phone: phoneNumber
  };
  var encryptData = encryptRequest(queryData);
  var $headerData = $.extend({}, {
    devicetoken: cookie_PENCILNEWSID
  },
    encryptData
  );

  $.post({
    url: v1url + "sms/send?verify=regverify",
    headers: $headerData,
    data: queryData,
    dataType: "json",
    success: function success(data) {
      if (data.code != 1000) {
        showWarning(data.message);
      } else {
        cb && cb(data.data);
      }
    },
    error: function error(jqXHR) { }
  });
}

/* 获取重置密码的验证码 */
function getForgetCode(cb) {
  var phone = $("#forget_phone_number").val();
  var queryData = {
    sms_type: "forgot",
    phone: phone
  };
  var encryptData = encryptRequest(queryData);
  var $headerData = $.extend({}, {
    devicetoken: cookie_PENCILNEWSID
  },
    encryptData
  );
  $.post({
    url: v1url + "sms/send?verify=regverify",
    headers: $headerData,
    data: queryData,
    dataType: "json",
    success: function (data) {
      if (data.code == 1000) {
        showSuccess(
          "验证码发送成功，" + data.data.time_last + "秒后方可再次获取"
        );
        cb && cb(data.data);
      } else {
        showWarning(data.message);
      }
    },
    error: function () { }
  });
}

/* 重置密码 */
function resetPassword() {
  var phone = $("#forget_phone_number").val();
  var password = $("#forget_pwd").val();
  var code = $("#forget_cert").val();
  $.ajax({
    type: "PUT",
    url: v1url + "user/forgot/",
    headers: {
      devicetoken: cookie_PENCILNEWSID
    },
    data: {
      phone: phone,
      code: code,
      password: password
    },
    dataType: "json",
    success: function (data) {
      closeLoadingGif();
      if (data["code"] == 1000) {
        showSuccess("重置密码成功！请登录");
        $("#login_bg").hide();
        $("body").removeClass("modal-open");
        $("#forget_pwd_container").hide();
        showPCLoginForm();
      } else {
        showWarning(data.message);
      }
    },
    error: function () { }
  });
}

/* 注册 */
function register() {
  var phoneNumber = $("#phone_number").val();
  var code = $("#cert").val();
  $.post({
    url: v1url + "user/auto-login",
    headers: {
      devicetoken: cookie_PENCILNEWSID
    },
    data: {
      phone: phoneNumber,
      code: code
    },
    dataType: "json",
    success: function (data) {
      closeLoadingGif();
      var alarm = data["code"];
      if (alarm != 1000) {
        showWarning(data["message"]);
      } else {
        $("#login_bg").hide();
        $("body").removeClass("modal-open");
        $("#regist_container").hide();
        $("#forget_pwd_container").hide();
        $("#login_container").hide();
        $(".login_sigin_con").hide();
        $("#login_on1").show();
        // $("#show_personal").html("<span class='icon-mine'></span>" + data["data"]["user"]["name"]);

        var user = data.data.user;
        saveUserToLocal(user);

        sa.login(user["uid"]);

        if ($.jStorage.get("isJumpApprove")) {
          if ($.jStorage.get("isJumpApprove") == "on") {
            $.jStorage.set("isJumpApprove", "off");
            if ($.jStorage.get("tempCertState")) {
              if ($.jStorage.get("tempCertState") != 1) {
                if (!IsPC) {
                  window.location = "/m_certification";
                } else {
                  window.location = "/usercenter?cate=my-certification";
                }
              } else {
                location.reload();
              }
            }
          } else {
            location.reload();
          }
        } else {
          location.reload();
        }
      }
    },
    error: function () { }
  });
}

/* 注册成功后直接登录 */
function registerAndLogin(username, password) {
  $.post({
    url: v1url + "user/login",
    headers: {
      devicetoken: cookie_PENCILNEWSID
    },
    data: {
      username: username,
      password: password
    },
    dataType: "json",
    success: function (data) {
      if (data["code"] == -3) { } else if (data["code"] == 1000) {
        $("#login_bg").hide();
        // $("#login_form").hide();
        $("body").removeClass("modal-open");
        $("#regist_container").hide();
        $("#forget_pwd_container").hide();
        $("#login_container").hide();
        $(".login_sigin_con").hide();
        $("#login_on1").show();
        // $("#show_personal").html("<span class='icon-mine'></span>" + data["data"]["user"]["username"]);
        var imgSrc = "/imgs/defaultAva200.png";
        //                     imgUrl = "https://cdn.pencilnews.cn/";
        if (data.data.user.profile.avatar) {
          imgSrc = imgUrl + data.data.user.profile.avatar;
        }
        $("#show_personal img").attr("src", imgUrl);
        var tempToken = data["data"]["user"]["token"];
        var tempTokenExpired = 43200000;
        var tempUsername = data["data"]["user"]["profile"]["username"];
        var tempSubscribe = data["data"]["user"]["profile"]["subscribed"];
        var tempEmail = data["data"]["user"]["profile"]["email"];
        var tempUid = data["data"]["user"]["profile"]["uid"];
        var tempName = data["data"]["user"]["profile"]["name"];
        if (tempName == "") {
          tempName = tempUsername;
        }
        var tempAvatar = imgSrc;
        var tempPhone = data["data"]["user"]["profile"]["phone"];
        var tempLevel = data["data"]["user"]["profile"]["level"];
        $.jStorage.set("localToken", tempToken);
        $.jStorage.set("localUsername", tempUsername);
        $.jStorage.set("localSubscribe", tempSubscribe);
        $.jStorage.set("localEmail", tempEmail);
        $.jStorage.set("localUid", tempUid);
        $.jStorage.set("localName", tempName);
        $.jStorage.set("tempAvatar", tempAvatar);
        $.jStorage.set("tempImgAvatarUrl", tempAvatar);
        $.jStorage.set("cert_wechat", "empty");
        $.jStorage.set("cert_email", "empty");
        $.jStorage.set("cert_name", "empty");

        saveUser({
          uid: tempUid,
          username: username,
          name: tempName,
          phone: tempPhone,
          email: tempEmail,
          token: tempToken,
          subscribed: tempSubscribe,
          avatar: tempAvatar,
          level: tempLevel
        });
        saveUser(data.data.user);
        if ($.jStorage.get("isJumpApprove")) {
          if ($.jStorage.get("isJumpApprove") == "on") {
            $.jStorage.set("isJumpApprove", "off");
            window.location = "/usercenter?cate=my-certification";
          } else {
            showSuccess(
              "想撩创业者？点击身份认证，立刻获得联系方式。页面将在5秒后刷新。"
            );
            setTimeout(function () {
              location.reload();
            }, 5000);
          }
        } else {
          showSuccess(
            "想撩创业者？点击身份认证，立刻获得联系方式。页面将在5秒后刷新。"
          );
          setTimeout(function () {
            location.reload();
          }, 5000);
        }
      } else {
        showWarning(data["message"]);
      }
    },
    error: function () { }
  });
}

/* 认证输入的内容是否有效，如果有效，可以进行注册 */
function registerValid() {
  return false;
}

/* 显示警告框 */
function showWarning(str) {
  toastr.options = {
    positionClass: "toast-top-center"
  };
  toastr.error(str);
}

/* 显示提示框 */
function showSuccess(str) {
  toastr.options = {
    positionClass: "toast-top-center"
  };
  toastr.success(str);
}

function showInfo(str) {
  toastr.options = {
    positionClass: "toast-top-center"
  };
  toastr.info(str);
}

/* 验证认证码 */
function validCode(obj) {
  var code = obj.val();
  if (code == "") {
    showWarning("验证码不能为空！");
    return false;
  } else {
    return true;
  }
}

/* 验证密码，如果长度不在8到16之间，弹窗警告*/
function validPassword(obj) {
  var password = obj.val();
  if (password.length < 8 || password.length > 16) {
    showWarning("密码长度在8和16之间，请正确输入");
    return false;
  } else {
    return true;
  }
}

/* 验证是否同意协议 */
function validAgree() {
  if ($("#agree").is(":checked")) {
    return true;
  } else {
    showWarning("请阅读并同意版权声明和隐私保护协议！");
    return false;
  }
}

/* 倒计时，计时完毕后，按钮可用 */
function countDown(obj, count) {
  //    $("#get_cert_btn").attr("disabled","true");
  obj.attr("disabled", "true");
  var timer = null;
  timer = setInterval(function () {
    count--;
    obj.html(count);
    //        $("#get_cert_btn").html(count);
    if (count == 0) {
      clearInterval(timer);
      obj.removeAttr("disabled");
      //$("#get_cert_btn").removeAttr("disabled");
      //$("#get_cert_btn").html("获取验证码");
      obj.html("获取验证码");
    }
  }, 1000);
}

/* 控制是否可以注册 */
function isEnableRegister() {
  if (validPhone($("#phone_number")) && validCode($("#cert"))) {
    return true;
  } else {
    return false;
  }
}

/* 验证是否可以点击重置密码 */
function isEnableResetpwd() {
  if (
    validPassword($("#forget_pwd")) &&
    validPhone($("#forget_phone_number")) &&
    validCode($("#forget_cert"))
  ) {
    return true;
  } else {
    return false;
  }
}

/* 验证用户名是否为空 */
function validUsername() {
  if ($("#username").val() == "") {
    showWarning("用户名不能为空，请正确填写");
    return false;
  } else {
    return true;
  }
}

/* 是否需要记住我 */
function rememberMe() {
  if ($("#remember_me").is(":checked")) {
    return true;
  } else {
    return false;
  }
}

function isLogin() {
  var token = $.jStorage.get("localToken"),
    username = $.jStorage.get("localName"),
    subscribe = $.jStorage.get("localSubscribe"),
    avatarSrc = $.jStorage.get("tempAvatar");
  //     var imgUrl = 'https://cdn.pencilnews.cn/';

  //更新头像，更新上传头像面板头像
  // $("#login_on1").hide();//初始登录头像隐藏 注册登录隐藏
  // $("#login_sign_in,#login_sign_up").hide();
  //     console.log("islogin() : "+avatarSrc);
  if (token) {
    validTokenEffective(token, function () {
      if (location.pathname == "/usercenter") {
        location.href = "/";
      }
    });
    if (avatarSrc) {
      avatarSrc = avatarSrc + "?" + new Date().getTime();
    } else {
      avatarSrc = "/imgs/defaultAva200.png";
    }

    $(".slidenavbar_personimg img").attr("src", avatarSrc);
    $("#login_bg").hide();
    $("body").removeClass("modal-open");
    $("#login_on1").show();

    $("#show_personal img").attr("src", avatarSrc);
    $("#slidenavbar_login").html(
      "<a href='/m_usercenter'>" + username + "</a>"
    );
    $("#slidenavbar_uc").show();
    $("#slidenav_exit").show();
    $.jStorage.set("isUsercenter", "off");
    if (subscribe == 1) {
      $("#subscribe").css({
        background: "#e7e7e7",
        "line-height": "2rem"
      });
    } else {
      $("#subscribe").css({
        background: "#fff000",
        "line-height": "2rem"
      });
    }
  } else {
    $(".login_sigin_con").show();
    $('.header-notify').hide();
  }
}

/* 登录 */
function login() {
  var username = $("#username").val();
  var password = $("#password").val();

  $.post({
    url: v1url + "user/login",
    headers: {
      devicetoken: cookie_PENCILNEWSID
    },
    data: {
      username: username,
      password: password
    },
    dataType: "json",
    success: function (data) {
      console.log(this.headers);
      closeLoadingGif();
      if (data["code"] == -3) { } else if (data["code"] == 1000) {
        $("#login_bg").hide();
        $("body").removeClass("modal-open");
        // $("#login_form").hide();
        $("#regist_container").hide();
        $("#forget_pwd_container").hide();
        $("#login_container").hide();
        $(".login_sigin_con").hide();
        $("#login_on1").show();
        // $("#show_personal").html("<span class='icon-mine'></span>" + data["data"]["user"]["name"]);

        var user = data.data.user;
        saveUserToLocal(user);

        sa.login(user["uid"]);

        if ($.jStorage.get("isJumpApprove")) {
          if ($.jStorage.get("isJumpApprove") == "on") {
            $.jStorage.set("isJumpApprove", "off");
            if ($.jStorage.get("tempCertState")) {
              if ($.jStorage.get("tempCertState") != 1) {
                if (!IsPC) {
                  window.location = "/m_certification";
                } else {
                  window.location = "/usercenter?cate=my-certification";
                }
              } else {
                location.reload();
              }
            }
          } else {
            location.reload();
          }
        } else {
          location.reload();
        }
      } else {
        showWarning(data["message"]);
      }
    },
    error: function () { }
  });
}

/* 登出 */
function logout() {
  var token = $.jStorage.get("localToken");
  $.ajax({
    type: "PUT",
    url: v1url + "user/logout",
    headers: {
      token: token,
      devicetoken: cookie_PENCILNEWSID
    },
    success: function (data) {
      if ($.jStorage.get("isUsercenter") == "on") {
        $.jStorage.flush();
        var url = "/";
        window.location.href = url;
      } else {
        $.jStorage.flush();
        location.reload();
      }
      sa.logout();
    }
  });
}

/*保存用户信息到本地*/
function saveUserToLocal(user) {
  var isRememberMe = $("#remember_me").is(":checked");
  var profile = user.profile;
  var imgSrc = "/imgs/defaultAva200.png",
    imgUrl = "https://cdn.pencilnews.cn/";

  if (profile.sex) {
    if (profile.sex == 2) {
      imgSrc = "/imgs/defaultAva200_female.png";
    }
  }

  if (user.profile.avatar) {
    imgSrc = imgUrl + profile.avatar;
  }
  $("#show_personal img").attr("src", imgSrc);
  var tempToken = user["token"];
  if (isRememberMe) {
    var tempTokenExpired = user["token_expired"];
  } else {
    var tempTokenExpired = 86400;
  }
  var tempUsername = profile["username"];
  var tempSubscribe = profile["subscribed"];
  var tempEmail = profile["email"];
  var tempUid = user["uid"];
  var tempName = profile["name"];
  if (tempName == "") {
    tempName = tempUsername;
  }
  var tempWechat = profile["wechat"];
  if (tempWechat == "") {
    tempWechat = "";
  }
  var tempRoleId = profile["role"]["level"];
  var tempAvatar = imgSrc;
  var tempPhone = profile["phone"];
  var tempCertState = user.profile.cert.state;
  var tempCertType = user.profile.cert.cert_type;
  var tempPayState = user.profile.is_pay;

  if (user.profile.cert.state) {
    $.jStorage.set("tempCertState", user.profile.cert.state);
  }

  if (profile["wechat"] && tempWechat != "") {
    $.jStorage.set("cert_wechat", tempWechat);
  } else {
    $.jStorage.set("cert_wechat", "empty");
  }
  if (profile["name"] && tempName != "") {
    $.jStorage.set("cert_name", tempName);
  } else {
    $.jStorage.set("cert_name", "empty");
  }
  if (profile["email"] && tempEmail != "") {
    $.jStorage.set("cert_email", tempEmail);
  } else {
    $.jStorage.set("cert_email", "empty");
  }

  $.jStorage.set("localToken", tempToken);
  $.jStorage.set("localUsername", tempUsername);
  $.jStorage.set("localSubscribe", tempSubscribe);
  $.jStorage.set("localEmail", tempEmail);
  $.jStorage.set("localUid", tempUid);
  $.jStorage.set("localName", tempName);
  $.jStorage.set("localRoleId", tempRoleId);
  $.jStorage.set("tempAvatar", tempAvatar);
  $.jStorage.set("tempPhone", tempPhone);
  $.jStorage.set("tempImgAvatarUrl", tempAvatar);
  $.jStorage.set("localWechat", tempWechat);
  $.jStorage.set("localPayState", tempPayState);
  $.jStorage.set("localCertType", tempCertType);

  saveUser({
    uid: tempUid,
    username: username,
    name: tempName,
    phone: tempPhone,
    email: tempEmail,
    token: tempToken,
    subscribed: tempSubscribe,
    avatar: tempAvatar
  });
  saveUser(user);
}
/* 获取导航栏分类内容并展示 */
function getNavbarCates() {
  $.get({
    type: "GET",
    url: v1url + "articles/cates",
    headers: {
      devicetoken: cookie_PENCILNEWSID
    },
    success: function (data) {
      if (data["code"] == 1000) {
        showNavbarCates(data);
        //   showNavDropDown();
      }
    }
  });
}

/* 根据数据库的字段显示首页导航栏的分类内容 */
function showNavbarCates(data) {
  var cates = data["data"]["cates"];
  for (i = cates.length - 1; i >= 0; i--) {
    var liId = "cates_" + i;
    if (cates[i]["is_navi"] != 0) {
      $("#nav_index").after(
        "<li class='nav_navbar' id='" +
        liId +
        "'><a href='/case'><span class='icon-nav" +
        i +
        "'></span>" +
        cates[i].name +
        "</a></li>"
      );
    }

    if (cates[i]["sons"].length != 0) {
      var sons = cates[i]["sons"];
      var sonsUlId = "cates_sons_" + i;
      $("#" + liId).addClass("navdropdown");
      $("#" + liId).append("<ul id='" + sonsUlId + "' ></ul>");
      for (j = sons.length - 1; j >= 0; j--) {
        if (sons[j]["is_navi"] != 0) {
          $("#" + sonsUlId).append(
            "<li class='nav_navbar'><a href='/industryinfo'>" +
            sons[j].name +
            "</a></li>"
          );
        }
      }
    }
  }
}

/* 鼠标移入显示资讯详情 */
function showNavDropDown() {
  $(".navdropdown").hover(
    function () {
      $(".navdropdown>ul").slideDown("300");
      $("li.navdropdown").css({
        background: "#2C2624",
        "box-shadow": "1px 1px 1px #25201E inset, -1px -1px 1px #25201E inset"
      });
    },
    function () {
      $(".navdropdown>ul").slideUp("300", function () {
        $("li.navdropdown").css({
          color: "#D6D6D6",
          background: "#3F3B3A",
          "box-shadow": "none",
          transition: ".3s all ease-in-out"
        });
      });
    }
  );
}

/* 根据情况订阅/取消订阅 */
function ifSubscribe() {
  var state = $.jStorage.get("localSubscribe");
  if (state == 0) {
    var email = $("#emailbox").val();
    $.jStorage.set("localSubscribe", 1);
    $.jStorage.set("localEmail", email);
    subscribe(1, email);
    $("#email_addr").val(email);
    if ($.jStorage.get("isUsercenter") == "off") {
      $("#emailbox").attr("disabled", "true");
      //  $("#emailbox").css().css("background","#fff");
      if (document.getElementById("emailbox")) {
        document.getElementById("emailbox").style.background = "#fff";
      }
    }
    return;
  }
  if (state == 1) {
    if ($.jStorage.get("isUsercenter") == "on") {
      var email = $("#email_addr").val();
      $.jStorage.set("localSubscribe", 0);
      subscribe(0, email);
      return;
    }
  }
}

/* 显示订阅按钮状态 */
function showSubscribeStatus() {
  var state = $.jStorage.get("localSubscribe");
  // console.log(state)
  if (state == 1) {
    $("#email_addr").html($.jStorage.get("localEmail"));
    if ($.jStorage.get("isUsercenter") == "off") {
      $("#emailbox").attr("disabled", "true");
      if (document.getElementById("emailbox")) {
        document.getElementById("emailbox").style.background = "#fff";
      }

      $("#subscribe").attr("disabled", "true");
      $("#subscribe").html("已订阅");
      //$("#emailbox").html($.jStorage.get("localEmail"));
      $("#uc_no_subscribe img").hide();
      $("#uc_subscribed img").show();
      $("p.uc_subscribe").html("已订阅“铅笔道”，我们将推送文章到您的邮箱。");
    } else {
      $("#emailbox").removeAttr("disabled");
      $("#subscribe").removeAttr("disabled");
      $("#subscribe").html("已订阅");
      $("#uc_no_subscribe img").hide();
      $("#uc_subscribed img").show();
      $("#uc_no_subscribe").css("background", "#13b800");
      $("#uc_subscribed").css("background", "#fff");
      $("p.uc_subscribe").html("已订阅“铅笔道”，我们将推送文章到您的邮箱。");
    }
  }
  if (state == 0) {
    $("#uc_subscribed img").hide();
    $("#uc_no_subscribe img").show();
    $("#uc_subscribed").css("background", "#e7e7e7");
    $("#uc_no_subscribe").css("background", "#fff");
    $("p.uc_subscribe").html(
      "您还未您还未订阅“铅笔道”，订阅后我们将推送文章到您的邮箱。"
    );
  }
}

/* 订阅/取消资讯 */
function subscribe(state, emailAddr) {
  var token = $.jStorage.get("localToken");
  $.post({
    url: v1url + "user/subscribe",
    data: {
      state: state,
      email: emailAddr
    },
    headers: {
      token: token,
      devicetoken: cookie_PENCILNEWSID
    },
    success: function (data) {
      if (data["code"] == 1000) {
        if (state == 1) {
          //showSuccess("订阅成功！");
          $("#subscribe").css({
            background: "#e7e7e7",
            "line-height": "2rem"
          });
          $("#subscribe").html("已订阅");
          $("#subscribe").attr("disabled", "true");
          if ($("#email_addr").val()) {
            $("#email_addr").val(email);
          }
        }
        if (state == 0) {
          //showSuccess("取消订阅成功！");
          $("#subscribe").css({
            background: "#fff000",
            "line-height": "2rem"
          });
          $("#subscribe").html("<span class='icon-subscribe'></span> 提交");
        }
      } else {
        if ($.jStorage.get("localSubscribe" == "off")) {
          showWarning(data["message"]);
        }
      }
    }
  });
}

function showActive() {
  var cateName = location.pathname;
  var cateIndex = null;
  var cateMap = {
    '/': 0,
    '/newsflash': 1,
    '/daochang_activity': 4,
    '/daochang_business': 4,
  };
  if (cateName) {
    for (var key in cateMap) {
      if (typeof cateMap[cateName] == 'number') {
        cateIndex = cateMap[cateName];
        $(".cates li")
          .eq(cateIndex)
          .addClass("cate-active");
        break;
      }
    }
  }
}

function saveUser(user) {
  //uid, username, name, phone, email, token, subscribed, avatar
  window.name = BASE64.encoder(JSON.stringify(user));
}

//点击微信登录
$(".wechat_qrcode_login").click(function () {
  var qrcode =
    "https://open.weixin.qq.com/connect/qrconnect?appid=wxf84a5064324ef6d0&redirect_uri=" +
    encodeURIComponent(
      "https://www.pencilnews.cn/wx_bind_phone?return_url=" + location.pathname
    ) +
    "&response_type=code&scope=snsapi_login&state=" +
    UTL.randomString() +
    UTL.createTimeStamp() +
    "#wechat_redirect";
  location.href = qrcode;
});

function getNotify() {
  $.ajax({
    method: "GET",
    url: v1url + "notify/un-read",
    headers: {
      token: $.jStorage.get("localToken"),
      devicetoken: cookie_PENCILNEWSID
    },
    dataType: "json",
    success: data => {
      // console.log(getCookie("PENCILNEWSID"))
      if (data.code == -1) {
        reLoginOnUc();
        return;
      } else if (data.code == 1000) {
        let notifyNum = data.data.total;
        if (notifyNum > 0) {
          let $badge = $(".js-notify-tab-badge");
          let $headerBadge = $(".js-header-notify-badge");
          $badge.show().text(notifyNum);
          $headerBadge.show();
        }
      } else {
        showWarning(data.message);
      }
    },
    error: err => {
      console.log(err);
    }
  });
}